.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App header {
  position: fixed;  /* Makes the header stay fixed */
  top: 0;             /* Positions the header at the top */
  left: 0;            /* Positions the header to the left edge */
  width: 100%;        /* Makes the header span the entire width */
  z-index: 10;        /* Ensures the header stays on top of other content */
  opacity: 0.8;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


button {
  background-color: white;
  color: black;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 25px;
  cursor: pointer; /* Indicate interactivity on hover */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5); /* Subtle elevation */
  font-size: 1.5rem;
  justify-content: space-between;
}

/* Hover effect */
button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8); /* Increase shadow on hover */
  background-color: #f5f5f5; /* Slight background color change */
}

.red_button {
  background-color: #D22F34;
  color: white;
  padding: 1rem;
  border-radius: 30px;
  cursor: pointer; /* Indicate interactivity on hover */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5); /* Subtle elevation */
}

/* Hover effect */
.red_button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8); /* Increase shadow on hover */
  background-color: #f5f5f5; /* Slight background color change */
  color: black;
  padding: 1rem;
}

.autoShow {
  animation: autoShowAnimation both;
  animation-timeline: view(60% 5%);
}
@keyframes autoShowAnimation {
  from{
    opacity: 0;
    transform: translateY(200px) scale(0.3);
  }
  to{
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
